define("discourse/plugins/discourse-data-explorer/discourse/lib/themeColor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = themeColor;
  function themeColor(name) {
    const style = getComputedStyle(document.body);
    return style.getPropertyValue(name);
  }
});