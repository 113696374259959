define("discourse/plugins/discourse-data-explorer/discourse/group-reports-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    resource: "group",
    map() {
      this.route("reports", function () {
        this.route("show", {
          path: "/:query_id"
        });
      });
    }
  };
});