define("discourse/plugins/discourse-data-explorer/discourse/components/result-types/json", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/components/modal/fullscreen-code", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _fullscreenCode, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="result-json">
    <div class="result-json-value">{{@ctx.value}}</div>
    <DButton
      class="result-json-button"
      @action={{action "viewJson"}}
      @icon="ellipsis-h"
      @title="explorer.view_json"
    />
  </div>
  */
  {
    "id": "CYU+4LhH",
    "block": "[[[10,0],[14,0,\"result-json\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"result-json-value\"],[12],[1,[30,1,[\"value\"]]],[13],[1,\"\\n  \"],[8,[39,0],[[24,0,\"result-json-button\"]],[[\"@action\",\"@icon\",\"@title\"],[[28,[37,1],[[30,0],\"viewJson\"],null],\"ellipsis-h\",\"explorer.view_json\"]],null],[1,\"\\n\"],[13]],[\"@ctx\"],false,[\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-data-explorer/discourse/components/result-types/json.hbs",
    "isStrictMode": false
  });
  class Json extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.inject]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "modal", [_service.inject]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    get parsedJson() {
      try {
        return JSON.parse(this.args.ctx.value);
      } catch {
        return null;
      }
    }
    static #_3 = (() => dt7948.n(this.prototype, "parsedJson", [_tracking.cached]))();
    viewJson() {
      this.modal.show(_fullscreenCode.default, {
        model: {
          code: this.parsedJson ? JSON.stringify(this.parsedJson, null, 2) : this.args.ctx.value,
          codeClasses: ""
        }
      });
    }
    static #_4 = (() => dt7948.n(this.prototype, "viewJson", [_object.action]))();
  }
  _exports.default = Json;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Json);
});