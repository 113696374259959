define("discourse/plugins/discourse-data-explorer/discourse/routes/admin-plugins-explorer", ["exports", "discourse/lib/ajax", "discourse/routes/discourse"], function (_exports, _ajax, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsExplorer extends _discourse.default {
    model() {
      if (!this.currentUser.admin) {
        // display "Only available to admins" message
        return {
          model: null,
          schema: null,
          disallow: true,
          groups: null
        };
      }
      const groupPromise = (0, _ajax.ajax)("/admin/plugins/explorer/groups.json");
      const schemaPromise = (0, _ajax.ajax)("/admin/plugins/explorer/schema.json", {
        cache: true
      });
      const queryPromise = this.store.findAll("query");
      return groupPromise.then(groups => {
        let groupNames = {};
        groups.forEach(g => {
          groupNames[g.id] = g.name;
        });
        return schemaPromise.then(schema => {
          return queryPromise.then(model => {
            model.forEach(query => {
              query.set("group_names", (query.group_ids || []).map(id => groupNames[id]));
            });
            return {
              model,
              schema,
              groups
            };
          });
        });
      });
    }
    setupController(controller, model) {
      controller.setProperties(model);
    }
  }
  _exports.default = AdminPluginsExplorer;
});