define("discourse/plugins/discourse-data-explorer/discourse/templates/connectors/group-reports-nav-item/nav-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <LinkTo @route="group.reports">
    {{d-icon "chart-bar"}}{{i18n "group.reports"}}
  </LinkTo>
  */
  {
    "id": "C6vpZXij",
    "block": "[[[8,[39,0],null,[[\"@route\"],[\"group.reports\"]],[[\"default\"],[[[[1,\"\\n  \"],[1,[28,[35,1],[\"chart-bar\"],null]],[1,[28,[35,2],[\"group.reports\"],null]],[1,\"\\n\"]],[]]]]]],[],false,[\"link-to\",\"d-icon\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-data-explorer/discourse/templates/connectors/group-reports-nav-item/nav-item.hbs",
    "isStrictMode": false
  });
});