define("discourse/plugins/discourse-data-explorer/discourse/components/result-types/category", ["exports", "@ember/component", "@glimmer/component", "discourse/helpers/category-link", "@ember/template-factory"], function (_exports, _component, _component2, _categoryLink, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @ctx.category}}
    {{this.categoryBadgeReplacement}}
  {{else}}
    <a href="{{@ctx.baseuri}}/t/{{@ctx.id}}">{{@ctx.id}}</a>
  {{/if}}
  */
  {
    "id": "m99yca22",
    "block": "[[[41,[30,1,[\"category\"]],[[[1,\"  \"],[1,[30,0,[\"categoryBadgeReplacement\"]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,3],[15,6,[29,[[30,1,[\"baseuri\"]],\"/t/\",[30,1,[\"id\"]]]]],[12],[1,[30,1,[\"id\"]]],[13],[1,\"\\n\"]],[]]]],[\"@ctx\"],false,[\"if\"]]",
    "moduleName": "discourse/plugins/discourse-data-explorer/discourse/components/result-types/category.hbs",
    "isStrictMode": false
  });
  class Category extends _component2.default {
    get categoryBadgeReplacement() {
      return (0, _categoryLink.categoryLinkHTML)(this.args.ctx.category, {
        allowUncategorized: true
      });
    }
  }
  _exports.default = Category;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Category);
});