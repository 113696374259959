define("discourse/plugins/discourse-data-explorer/discourse/routes/group-reports-show", ["exports", "@ember/service", "discourse/lib/ajax", "discourse/routes/discourse"], function (_exports, _service, _ajax, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class GroupReportsShowRoute extends _discourse.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.inject]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    model(params) {
      const group = this.modelFor("group");
      return (0, _ajax.ajax)(`/g/${group.name}/reports/${params.query_id}`).then(response => {
        const query = response.query;
        const queryGroup = response.query_group;
        const queryParamInfo = query.param_info;
        const queryParams = queryParamInfo.reduce((acc, param) => {
          acc[param.identifier] = param.default;
          return acc;
        }, {});
        return {
          model: Object.assign({
            params: queryParams
          }, query),
          group,
          queryGroup,
          results: null,
          showResults: false
        };
      }).catch(() => {
        this.router.transitionTo("group.members", group);
      });
    }
    setupController(controller, model) {
      controller.setProperties(model);
    }
  }
  _exports.default = GroupReportsShowRoute;
});