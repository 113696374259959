define("discourse/plugins/discourse-data-explorer/discourse/components/result-types/html", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{html-safe @ctx.value}}
  */
  {
    "id": "i0ad8qW6",
    "block": "[[[1,[28,[35,0],[[30,1,[\"value\"]]],null]]],[\"@ctx\"],false,[\"html-safe\"]]",
    "moduleName": "discourse/plugins/discourse-data-explorer/discourse/components/result-types/html.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});