define("discourse/plugins/discourse-data-explorer/discourse/components/query-row-content", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/string", "discourse/lib/utilities", "discourse-common/lib/get-url", "discourse/plugins/discourse-data-explorer/discourse/components/result-types/text", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _string, _utilities, _getUrl, _text, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <tr>
    {{#each this.results as |result|}}
      <td>
        <result.component
          @ctx={{result.ctx}}
          @params={{result.params}}
          @textValue={{result.textValue}}
        />
      </td>
    {{/each}}
  </tr>
  */
  {
    "id": "fBLn+HHd",
    "block": "[[[10,\"tr\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"results\"]]],null]],null],null,[[[1,\"    \"],[10,\"td\"],[12],[1,\"\\n      \"],[8,[30,1,[\"component\"]],null,[[\"@ctx\",\"@params\",\"@textValue\"],[[30,1,[\"ctx\"]],[30,1,[\"params\"]],[30,1,[\"textValue\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[1]],null],[13]],[\"result\"],false,[\"each\",\"-track-array\"]]",
    "moduleName": "discourse/plugins/discourse-data-explorer/discourse/components/query-row-content.hbs",
    "isStrictMode": false
  });
  class QueryRowContent extends _component2.default {
    get results() {
      return this.args.columnComponents.map((t, idx) => {
        const value = this.args.row[idx],
          id = parseInt(value, 10);
        const ctx = {
          value,
          id,
          baseuri: (0, _getUrl.default)("")
        };
        if (this.args.row[idx] === null) {
          return {
            component: _text.default,
            textValue: "NULL"
          };
        } else if (t.name === "text") {
          return {
            component: _text.default,
            textValue: (0, _utilities.escapeExpression)(this.args.row[idx].toString())
          };
        }
        const lookupFunc = this.args[`lookup${(0, _string.capitalize)(t.name)}`];
        if (lookupFunc) {
          ctx[t.name] = lookupFunc.call(this.args, id);
        }
        if (t.name === "url") {
          let [url, name] = guessUrl(value);
          ctx["href"] = url;
          ctx["target"] = name;
        }
        try {
          return {
            component: t.component || _text.default,
            ctx
          };
        } catch (e) {
          return "error";
        }
      });
    }
    static #_ = (() => dt7948.n(this.prototype, "results", [_tracking.cached]))();
  }
  _exports.default = QueryRowContent;
  function guessUrl(t) {
    let [dest, name] = [t, t];
    const split = t.split(/,(.+)/);
    if (split.length > 1) {
      name = split[0];
      dest = split[1];
    }
    return [dest, name];
  }
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, QueryRowContent);
});