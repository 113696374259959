define("discourse/plugins/discourse-data-explorer/discourse/controllers/admin-plugins-explorer", ["exports", "@glimmer/tracking", "@ember/controller", "@ember/object", "@ember/service", "rsvp", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/utils/decorators", "I18n", "discourse/plugins/discourse-data-explorer/discourse/components/modal/query-help", "discourse/plugins/discourse-data-explorer/discourse/models/query"], function (_exports, _tracking, _controller, _object, _service, _rsvp, _ajax, _ajaxError, _decorators, _I18n, _queryHelp, _query) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const NoQuery = _query.default.create({
    name: "No queries",
    fake: true,
    group_ids: []
  });
  class PluginsExplorerController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "modal", [_service.inject]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "dialog", [_service.inject]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "appEvents", [_service.inject]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "router", [_service.inject]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "sortByProperty", [_tracking.tracked], function () {
      return "last_run_at";
    }))();
    #sortByProperty = (() => (dt7948.i(this, "sortByProperty"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "sortDescending", [_tracking.tracked], function () {
      return true;
    }))();
    #sortDescending = (() => (dt7948.i(this, "sortDescending"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "params", [_tracking.tracked]))();
    #params = (() => (dt7948.i(this, "params"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "search", [_tracking.tracked]))();
    #search = (() => (dt7948.i(this, "search"), void 0))();
    static #_9 = (() => dt7948.g(this.prototype, "newQueryName", [_tracking.tracked]))();
    #newQueryName = (() => (dt7948.i(this, "newQueryName"), void 0))();
    static #_10 = (() => dt7948.g(this.prototype, "showCreate", [_tracking.tracked]))();
    #showCreate = (() => (dt7948.i(this, "showCreate"), void 0))();
    static #_11 = (() => dt7948.g(this.prototype, "editingName", [_tracking.tracked], function () {
      return false;
    }))();
    #editingName = (() => (dt7948.i(this, "editingName"), void 0))();
    static #_12 = (() => dt7948.g(this.prototype, "editingQuery", [_tracking.tracked], function () {
      return false;
    }))();
    #editingQuery = (() => (dt7948.i(this, "editingQuery"), void 0))();
    static #_13 = (() => dt7948.g(this.prototype, "selectedQueryId", [_tracking.tracked]))();
    #selectedQueryId = (() => (dt7948.i(this, "selectedQueryId"), void 0))();
    static #_14 = (() => dt7948.g(this.prototype, "loading", [_tracking.tracked], function () {
      return false;
    }))();
    #loading = (() => (dt7948.i(this, "loading"), void 0))();
    static #_15 = (() => dt7948.g(this.prototype, "showResults", [_tracking.tracked], function () {
      return false;
    }))();
    #showResults = (() => (dt7948.i(this, "showResults"), void 0))();
    static #_16 = (() => dt7948.g(this.prototype, "hideSchema", [_tracking.tracked], function () {
      return false;
    }))();
    #hideSchema = (() => (dt7948.i(this, "hideSchema"), void 0))();
    static #_17 = (() => dt7948.g(this.prototype, "results", [_tracking.tracked], function () {
      return this.selectedItem.results;
    }))();
    #results = (() => (dt7948.i(this, "results"), void 0))();
    static #_18 = (() => dt7948.g(this.prototype, "dirty", [_tracking.tracked], function () {
      return false;
    }))();
    #dirty = (() => (dt7948.i(this, "dirty"), void 0))();
    queryParams = ["params", {
      selectedQueryId: "id"
    }];
    explain = false;
    acceptedImportFileTypes = ["application/json"];
    order = null;
    get validQueryPresent() {
      return !!this.selectedItem.id;
    }
    get saveDisabled() {
      return !this.dirty;
    }
    get runDisabled() {
      return this.dirty;
    }
    get sortedQueries() {
      const sortedQueries = this.model.sortBy(this.sortByProperty);
      return this.sortDescending ? sortedQueries.reverse() : sortedQueries;
    }
    get parsedParams() {
      return this.params ? JSON.parse(this.params) : null;
    }
    get filteredContent() {
      const regexp = new RegExp(this.search, "i");
      return this.sortedQueries.filter(result => regexp.test(result.name) || regexp.test(result.description));
    }
    get createDisabled() {
      return (this.newQueryName || "").trim().length === 0;
    }
    get selectedItem() {
      const query = this.model.findBy("id", parseInt(this.selectedQueryId, 10));
      return query || NoQuery;
    }
    get editDisabled() {
      return parseInt(this.selectedQueryId, 10) < 0 ? true : false;
    }
    get groupOptions() {
      return this.groups.filter(g => g.id !== 0).map(g => {
        return {
          id: g.id,
          name: g.name
        };
      });
    }
    get othersDirty() {
      return !!this.model.find(q => q !== this.selectedItem && this.dirty);
    }
    addCreatedRecord(record) {
      this.model.pushObject(record);
      this.selectedQueryId = record.id;
      this.dirty = false;
      this.setProperties({
        showResults: false,
        results: null,
        editingName: true,
        editingQuery: true
      });
    }
    async save() {
      try {
        this.loading = true;
        await this.selectedItem.save();
        this.dirty = false;
        this.editingName = false;
      } catch (error) {
        (0, _ajaxError.popupAjaxError)(error);
        throw error;
      } finally {
        this.loading = false;
      }
    }
    static #_19 = (() => dt7948.n(this.prototype, "save", [_object.action]))();
    saveAndRun() {
      this.save().then(() => this.run());
    }
    static #_20 = (() => dt7948.n(this.prototype, "saveAndRun", [_object.action]))();
    async _importQuery(file) {
      const json = await this._readFileAsTextAsync(file);
      const query = this._parseQuery(json);
      const record = this.store.createRecord("query", query);
      const response = await record.save();
      return response.target;
    }
    _parseQuery(json) {
      const parsed = JSON.parse(json);
      const query = parsed.query;
      if (!query || !query.sql) {
        throw new TypeError();
      }
      query.id = 0; // 0 means no Id yet
      return query;
    }
    _readFileAsTextAsync(file) {
      return new _rsvp.Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsText(file);
      });
    }
    dragMove(e) {
      if (!e.movementY && !e.movementX) {
        return;
      }
      const editPane = document.querySelector(".query-editor");
      const target = editPane.querySelector(".panels-flex");
      const grippie = editPane.querySelector(".grippie");

      // we need to get the initial height / width of edit pane
      // before we manipulate the size
      if (!this.initialPaneWidth && !this.originalPaneHeight) {
        this.originalPaneWidth = target.clientWidth;
        this.originalPaneHeight = target.clientHeight;
      }
      const newHeight = Math.max(this.originalPaneHeight, target.clientHeight + e.movementY);
      const newWidth = Math.max(this.originalPaneWidth, target.clientWidth + e.movementX);
      target.style.height = newHeight + "px";
      target.style.width = newWidth + "px";
      grippie.style.width = newWidth + "px";
      this.appEvents.trigger("ace:resize");
    }
    static #_21 = (() => dt7948.n(this.prototype, "dragMove", [_decorators.bind]))();
    didStartDrag() {}
    static #_22 = (() => dt7948.n(this.prototype, "didStartDrag", [_decorators.bind]))();
    didEndDrag() {}
    static #_23 = (() => dt7948.n(this.prototype, "didEndDrag", [_decorators.bind]))();
    scrollTop() {
      window.scrollTo(0, 0);
      this.editingName = false;
      this.editingQuery = false;
    }
    static #_24 = (() => dt7948.n(this.prototype, "scrollTop", [_decorators.bind]))();
    updateGroupIds(value) {
      this.dirty = true;
      this.selectedItem.set("group_ids", value);
    }
    static #_25 = (() => dt7948.n(this.prototype, "updateGroupIds", [_object.action]))();
    updateHideSchema(value) {
      this.hideSchema = value;
    }
    static #_26 = (() => dt7948.n(this.prototype, "updateHideSchema", [_object.action]))();
    async import(files) {
      try {
        this.loading = true;
        const file = files[0];
        const record = await this._importQuery(file);
        this.addCreatedRecord(record);
      } catch (e) {
        if (e.jqXHR) {
          (0, _ajaxError.popupAjaxError)(e);
        } else if (e instanceof SyntaxError) {
          this.dialog.alert(_I18n.default.t("explorer.import.unparseable_json"));
        } else if (e instanceof TypeError) {
          this.dialog.alert(_I18n.default.t("explorer.import.wrong_json"));
        } else {
          this.dialog.alert(_I18n.default.t("errors.desc.unknown"));
          // eslint-disable-next-line no-console
          console.error(e);
        }
      } finally {
        this.loading = false;
        this.dirty = true;
      }
    }
    static #_27 = (() => dt7948.n(this.prototype, "import", [_object.action]))();
    displayCreate() {
      this.showCreate = true;
    }
    static #_28 = (() => dt7948.n(this.prototype, "displayCreate", [_object.action]))();
    editName() {
      this.editingName = true;
    }
    static #_29 = (() => dt7948.n(this.prototype, "editName", [_object.action]))();
    editQuery() {
      this.editingQuery = true;
    }
    static #_30 = (() => dt7948.n(this.prototype, "editQuery", [_object.action]))();
    download() {
      window.open(this.selectedItem.downloadUrl, "_blank");
    }
    static #_31 = (() => dt7948.n(this.prototype, "download", [_object.action]))();
    goHome() {
      this.order = null;
      this.showResults = false;
      this.selectedQueryId = null;
      this.params = null;
      this.sortByProperty = "last_run_at";
      this.sortDescending = true;
      this.router.transitionTo({
        queryParams: {
          id: null,
          params: null
        }
      });
    }
    static #_32 = (() => dt7948.n(this.prototype, "goHome", [_object.action]))();
    showHelpModal() {
      this.modal.show(_queryHelp.default);
    }
    static #_33 = (() => dt7948.n(this.prototype, "showHelpModal", [_object.action]))();
    resetParams() {
      this.selectedItem.resetParams();
    }
    static #_34 = (() => dt7948.n(this.prototype, "resetParams", [_object.action]))();
    updateSortProperty(property) {
      if (this.sortByProperty === property) {
        this.sortDescending = !this.sortDescending;
      } else {
        this.sortByProperty = property;
        this.sortDescending = true;
      }
    }
    static #_35 = (() => dt7948.n(this.prototype, "updateSortProperty", [_object.action]))();
    async create() {
      try {
        const name = this.newQueryName.trim();
        this.loading = true;
        this.showCreate = false;
        const result = await this.store.createRecord("query", {
          name
        }).save();
        this.addCreatedRecord(result.target);
      } catch (error) {
        (0, _ajaxError.popupAjaxError)(error);
      } finally {
        this.loading = false;
        this.dirty = true;
      }
    }
    static #_36 = (() => dt7948.n(this.prototype, "create", [_object.action]))();
    async discard() {
      try {
        this.loading = true;
        const result = await this.store.find("query", this.selectedItem.id);
        this.selectedItem.setProperties(result.getProperties(_query.default.updatePropertyNames));
        if (!this.selectedItem.group_ids || !Array.isArray(this.selectedItem.group_ids)) {
          this.selectedItem.set("group_ids", []);
        }
        this.dirty = false;
      } catch (error) {
        (0, _ajaxError.popupAjaxError)(error);
      } finally {
        this.loading = false;
      }
    }
    static #_37 = (() => dt7948.n(this.prototype, "discard", [_object.action]))();
    async destroyQuery() {
      try {
        this.loading = true;
        this.showResults = false;
        await this.store.destroyRecord("query", this.selectedItem);
        this.selectedItem.set("destroyed", true);
      } catch (error) {
        (0, _ajaxError.popupAjaxError)(error);
      } finally {
        this.loading = false;
      }
    }
    static #_38 = (() => dt7948.n(this.prototype, "destroyQuery", [_object.action]))();
    async recover() {
      try {
        this.loading = true;
        this.showResults = true;
        await this.selectedItem.save();
        this.selectedItem.set("destroyed", false);
      } catch (error) {
        (0, _ajaxError.popupAjaxError)(error);
      } finally {
        this.loading = false;
      }
    }
    static #_39 = (() => dt7948.n(this.prototype, "recover", [_object.action]))();
    updateParams(identifier, value) {
      this.selectedItem.set(`params.${identifier}`, value);
    }
    static #_40 = (() => dt7948.n(this.prototype, "updateParams", [_object.action]))();
    updateSearch(value) {
      this.search = value;
    }
    static #_41 = (() => dt7948.n(this.prototype, "updateSearch", [_object.action]))();
    updateNewQueryName(value) {
      this.newQueryName = value;
    }
    static #_42 = (() => dt7948.n(this.prototype, "updateNewQueryName", [_object.action]))();
    setDirty() {
      this.dirty = true;
    }
    static #_43 = (() => dt7948.n(this.prototype, "setDirty", [_object.action]))();
    exitEdit() {
      this.editingName = false;
    }
    static #_44 = (() => dt7948.n(this.prototype, "exitEdit", [_object.action]))();
    run() {
      this.setProperties({
        loading: true,
        showResults: false,
        params: JSON.stringify(this.selectedItem.params)
      });
      (0, _ajax.ajax)("/admin/plugins/explorer/queries/" + this.selectedItem.id + "/run", {
        type: "POST",
        data: {
          params: JSON.stringify(this.selectedItem.params),
          explain: this.explain
        }
      }).then(result => {
        this.results = result;
        if (!result.success) {
          this.showResults = false;
          return;
        }
        this.showResults = true;
      }).catch(err => {
        this.showResults = false;
        if (err.jqXHR && err.jqXHR.status === 422 && err.jqXHR.responseJSON) {
          this.results = err.jqXHR.responseJSON;
        } else {
          (0, _ajaxError.popupAjaxError)(err);
        }
      }).finally(() => this.loading = false);
    }
    static #_45 = (() => dt7948.n(this.prototype, "run", [_object.action]))();
  }
  _exports.default = PluginsExplorerController;
});