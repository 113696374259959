define("discourse/plugins/discourse-data-explorer/discourse/components/query-results-wrapper", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @results}}
    <div class="query-results">
      {{#if @showResults}}
        <QueryResult @query={{@query}} @content={{@results}} />
      {{else}}
        {{#each @results.errors as |err|}}
          <pre class="query-error"><code>{{~err}}</code></pre>
        {{/each}}
      {{/if}}
    </div>
  {{/if}}
  */
  {
    "id": "CMQdIpMe",
    "block": "[[[41,[30,1],[[[1,\"  \"],[10,0],[14,0,\"query-results\"],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"      \"],[8,[39,1],null,[[\"@query\",\"@content\"],[[30,3],[30,1]]],null],[1,\"\\n\"]],[]],[[[42,[28,[37,3],[[28,[37,3],[[30,1,[\"errors\"]]],null]],null],null,[[[1,\"        \"],[10,\"pre\"],[14,0,\"query-error\"],[12],[10,\"code\"],[12],[1,[30,4]],[13],[13],[1,\"\\n\"]],[4]],null]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[\"@results\",\"@showResults\",\"@query\",\"err\"],false,[\"if\",\"query-result\",\"each\",\"-track-array\"]]",
    "moduleName": "discourse/plugins/discourse-data-explorer/discourse/components/query-results-wrapper.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});