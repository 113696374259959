define("discourse/plugins/discourse-data-explorer/discourse/connectors/group-reports-nav-item/nav-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    shouldRender(args) {
      return args.group.has_visible_data_explorer_queries;
    }
  };
});